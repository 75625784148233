import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import logo from "../assets/img/goalprize_logo.png";
import COLOR from "../assets/css/Color.js";
import { useNavigate } from "react-router-dom";
import SnackBar from "../components/Snackbar.js";
import API from "../api/index.js";
import Loader from "../components/Loader.js";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { useTranslation } from "react-i18next";

let api = new API();

export default function SignIn({}) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [checked, setChecked] = useState(false);
  const [errorEmail, setErrorEmail] = useState(null);
  const [errorPassword, setErrorPassword] = useState(null);
  const [errorChecked, setErrorChecked] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    return localStorage.getItem("language") || "en";
  });

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem("language", selectedLanguage);
  }, [selectedLanguage, i18n]);

  function isValidEmail(email) {
    return /^\s*[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\s*$/.test(
      email
    );
  }

  function isValidPassword(password) {
    let hasLetter = false;
    let hasNumber = false;
    let hasSpecialChar = false;

    for (const char of password) {
      if (/[a-zA-Z]/.test(char)) {
        hasLetter = true;
      } else if (/\d/.test(char)) {
        hasNumber = true;
      } else if (/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(char)) {
        hasSpecialChar = true;
      }
    }

    const isLengthValid = password.length >= 8;

    return hasLetter && hasNumber && hasSpecialChar && isLengthValid;
  }

  const emailHandleChange = (event) => {
    if (!isValidEmail(event.target.value)) {
      setErrorEmail(t("valid_email"));
    } else {
      setErrorEmail(null);
    }
    setEmail(event.target.value);
  };

  const passwordHandleChange = (event) => {
    const value = event.target.value;
    if (!isValidPassword(value)) {
      setErrorPassword(t("password_format"));
    } else {
      setErrorPassword(null);
    }
    setPassword(value);
  };

  const radioHandleChange = (event) => {
    setChecked(event.target.checked);
  };

  function onCreateNewAccountBtnClick() {
    if (!email || errorEmail) {
      setErrorEmail(t("valid_email"));
    }
    if (!password || errorPassword) {
      setErrorPassword(t("password_format"));
    }
    if (!checked) {
      setErrorChecked(t("please_agree_GTS"));
    } else {
    /* if (!email || errorEmail || !password || errorPassword || !checked) {
      setErrorMessage(t("password_format"));
      return;
    } */
      setErrorChecked(false);
      setIsLoading(true);
      const token = "";
      let data = JSON.stringify({
        email: email.toLowerCase(),
        password: password,
        token: token || t("no_token"),
        platform: 1,
      });
      api
        .signupAPI(data)
        .then((json) => {
          if (json.status == 200) {
            setIsLoading(false);
            navigate("OtpPage", { state: email });
          } else if (json.status == 400) {
            setErrorMessage(t("server_error"));
            setIsLoading(false);
          } else {
            setErrorMessage(t("server_error"));
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setErrorMessage(error.response.data.message);
        });
    }
  }

  function termsOfServiceClick() {
    navigate("TermsOfService", {});
  }

  return (
    <Container component="main" maxWidth="xs">
      <Loader visible={isLoading} />
      <SnackBar
        open={errorChecked}
        message={t("please_agree_GTS")}
        onClose={() => setErrorChecked(false)}
        severity={"error"}
      />
      <SnackBar
        open={errorMessage}
        message={errorMessage}
        onClose={() => setErrorMessage(false)}
        severity={"error"}
      />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          sx={{
            height: 75,
            width: 300,
          }}
          src={logo}
        />
        <Box
          sx={{
            mt: 4,
            display: "flex",
            alignItems: "center",
          }}
        >
          <InputLabel
            id="demo-simple-select-helper-label"
            sx={{ color: "black", marginRight: 1 }}
          >
            {t("selected_language")}
          </InputLabel>
          <Select
            value={selectedLanguage}
            onChange={(event) => setSelectedLanguage(event.target.value)}
            size="small"
          >
            <MenuItem value="en">English</MenuItem>
            <MenuItem value="th">ภาษาไทย</MenuItem>
            {/* Add more languages as needed */}
          </Select>
        </Box>
        <Typography component="h1" variant="h5" sx={{ mt: 3 }}>
          {t("create_new_account_title")}
        </Typography>
        <Typography variant="subtitle-1" sx={{ mt: 3 }}>
          {t("only_owner")}
        </Typography>
        <Box
          sx={{
            mt: 1,
          }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label={t("email")}
            name="email"
            autoComplete="email"
            autoFocus
            defaultValue={email}
            onChange={emailHandleChange}
            error={!!errorEmail}
            helperText={errorEmail || null}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label={t("password")}
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            defaultValue={password}
            onChange={passwordHandleChange}
            error={!!errorPassword}
            helperText={errorPassword || null}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleTogglePassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Typography variant="h10"> {t("password_format")}</Typography>

          <FormControlLabel
            sx={{ mt: 2 }}
            control={
              <Checkbox
                value="remember"
                color="primary"
                onChange={radioHandleChange}
              />
            }
            label={
              <div>
                <span>{t("agree_GTS")} </span>
                <Link to="/TermsOfService" target="_blank">
                  {" "}
                  <span
                    style={{
                      color: COLOR.GP_BLUE,
                      textDecoration: "underline",
                    }}
                  >
                    {t("GTS")}
                  </span>
                </Link>
              </div>
            }
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, backgroundColor: COLOR.GP_BLUE }}
            onClick={onCreateNewAccountBtnClick}
          >
            {t("create_new_account_button")}
          </Button>
        </Box>
      </Box>
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        sx={{ mb: 4 }}
      >
        {" © "} {new Date().getFullYear()} {"FastUsers Co. Ltd."}
      </Typography>
    </Container>
  );
}
